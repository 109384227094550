
import {defineComponent, reactive, UnwrapRef, ref} from 'vue';
import router from "@/router";
import {message} from "ant-design-vue";
import SessionStorageKeys from "@/common/SessionStorageKeys";
import Login from "@/request/Login";

export default defineComponent({
    setup() {
        const formData: UnwrapRef<any> = reactive({
            userName: '',
            password: ''
        });

        const loading = ref<boolean>(false);

        const onSubmit = () => {
            loading.value = true;
            Login.doLogin(formData).then((body: any) => {
                loading.value = false;
                if (body.code === 0) {
                    console.log(body.data)
                    sessionStorage.setItem(SessionStorageKeys.token, body.data.token);
                    sessionStorage.setItem(SessionStorageKeys.type, body.data.type);
                    sessionStorage.setItem(SessionStorageKeys.companyName, body.data.companyName);
                    sessionStorage.setItem(SessionStorageKeys.name, body.data.name);
                    sessionStorage.setItem(SessionStorageKeys.companyId, body.data.companyId);
                    router.replace('/index');
                } else {
                    message.error(body.msg);
                }
            })
        };

        return {
            loading,
            formData,
            onSubmit,
        };
    },
});
