<template>
    <div class="container">
        <div class="login">
            <a-form :model="formData" :label-col="{span: 4}" :wrapper-col="{span: 18}" style="width: 500px;">
                <a-form-item label="用户名">
                    <a-input v-model:value="formData.userName" autocomplete="off"/>
                </a-form-item>
                <a-form-item label="密码">
                    <a-input v-model:value="formData.password" type="password" autocomplete="off"/>
                </a-form-item>
                <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
                    <a-button type="primary" html-type="submit" @click="onSubmit" :loading="loading">登陆</a-button>
                </a-form-item>
            </a-form>
        </div>
    </div>
</template>
<script lang="ts">
import {defineComponent, reactive, UnwrapRef, ref} from 'vue';
import router from "@/router";
import {message} from "ant-design-vue";
import SessionStorageKeys from "@/common/SessionStorageKeys";
import Login from "@/request/Login";

export default defineComponent({
    setup() {
        const formData: UnwrapRef<any> = reactive({
            userName: '',
            password: ''
        });

        const loading = ref<boolean>(false);

        const onSubmit = () => {
            loading.value = true;
            Login.doLogin(formData).then((body: any) => {
                loading.value = false;
                if (body.code === 0) {
                    console.log(body.data)
                    sessionStorage.setItem(SessionStorageKeys.token, body.data.token);
                    sessionStorage.setItem(SessionStorageKeys.type, body.data.type);
                    sessionStorage.setItem(SessionStorageKeys.companyName, body.data.companyName);
                    sessionStorage.setItem(SessionStorageKeys.name, body.data.name);
                    sessionStorage.setItem(SessionStorageKeys.companyId, body.data.companyId);
                    router.replace('/index');
                } else {
                    message.error(body.msg);
                }
            })
        };

        return {
            loading,
            formData,
            onSubmit,
        };
    },
});
</script>

<style scoped lang="less">

.container {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;

    .login {
        width: 550px;
        height: 320px;
        margin: 0 auto;
        padding: 60px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        box-shadow: 0 0 6px 0px rgba(0, 0, 0, 0.2);
    }
}

</style>