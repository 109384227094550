import {Request} from "@/Request";

class Login extends Request {
    
    public path: string = '/user';
    
    doLogin = (data: {userName: string, password: string})  => {
        return this.formPost(`${this.path}/login`, data);
    }
}

export default new Login();